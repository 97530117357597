import { Link } from '@remix-run/react'
import { ATOLLS_MAP } from '#app/utils/mb/atolls.ts'
import { hotelNameFormat } from '#app/utils/mb/hotel-name-formatter.ts'
import { HotelType } from '#app/utils/mb/status.ts'
import { cn, getHotelImgSrc } from '#app/utils/misc.tsx'
import { HotelTypeIcon } from './hotel-type'
import { buttonVariants } from './ui/button'
import { Icon } from './ui/icon'

export type PublishedHotel = {
	id: string
	name: string
	description: string
	type: string
	atoll: string
	island: string
	defaultPrice: number
	roomCount: number
	images: {
		id: string
		altText: string | null
	}[]
}

export type HotelSwiperProps = {
	title: string
	hotels: PublishedHotel[]
}

export function HotelSwiper({ title, hotels }: HotelSwiperProps) {
	return (
		<div className="container">
			<div className="w-full">
				<div className="flex items-baseline justify-between">
					<h2 className="mb-8 text-h2 uppercase tracking-tight text-foreground lg:text-h1">
						{title}
					</h2>
				</div>

				<div className="grid grid-cols-1">
					<div className="scrollbar-hide flex w-full snap-x snap-proximity gap-8 overflow-x-auto overscroll-x-none">
						{hotels.map((hotel) => {
							const key = `${hotelNameFormat(hotel.name)}-${hotel.id}`
							const image = hotel.images[0]
							const atoll = ATOLLS_MAP[hotel.atoll]
							const roomCount = hotel.roomCount

							return (
								<div
									key={key}
									className="block h-full w-[75%] shrink-0 snap-start lg:w-[29%]"
								>
									<div className="group flex h-full w-full flex-col justify-between">
										<div>
											<div className="mb-4 aspect-[3/2] w-full overflow-hidden">
												<div className="w-full">
													{image ? (
														<img
															alt={image.altText || ''}
															src={getHotelImgSrc(image.id)}
															className="h-full w-full object-cover"
														/>
													) : (
														<div className="h-full w-full bg-gray-200"></div>
													)}
												</div>
											</div>
											<p className="mb-2 text-xs uppercase text-muted-foreground lg:text-sm">
												<HotelTypeIcon
													type={hotel.type as HotelType}
													removeIcon
												/>
											</p>
											<Link
												to={`/hotels/${key}`}
												className="mb-2 line-clamp-2 text-h5 uppercase text-foreground underline hover:text-foreground/70 lg:text-h4"
											>
												{hotel.name}
											</Link>
											<p className="mb-2 text-xs text-muted-foreground lg:text-sm">
												<Icon name="map-pin">
													{atoll ? `${atoll.shortKey}, ` : ''} {hotel.island}
												</Icon>
											</p>
											{/* {atoll && (
												<p className="mb-2 text-xs text-muted-foreground lg:text-sm">
													<Icon name="plane">
														{atoll.distanceKm.toFixed(1)} km
													</Icon>
												</p>
											)} */}
											{roomCount > 1 && (
												<p className="mb-2 text-xs text-muted-foreground lg:text-sm">
													<Icon name="bed-double">
														{roomCount}{' '}
														{hotel.type === HotelType.RESORT ? 'villa' : 'room'}{' '}
														options
													</Icon>
												</p>
											)}
											<p className="mb-2 text-xs text-muted-foreground lg:text-sm">
												From ${hotel.defaultPrice.toFixed(2)} per night
											</p>
										</div>

										<div className="flex w-full flex-col items-center justify-center gap-4">
											<div className="align-end mt-8 flex w-full gap-4">
												<Link
													to={`/hotels/${key}`}
													className={cn(
														buttonVariants({
															variant: 'outline',
														}),
														'w-full',
													)}
												>
													View more
												</Link>
												<Link
													to={`/reservation/make?hotel=${hotel.id}`}
													className={cn(buttonVariants(), 'w-full')}
												>
													Reserve
												</Link>
											</div>
											<p className="text-sm text-muted-foreground">
												You won't be charged to reserve.
											</p>
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	)
}
